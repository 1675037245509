// General
import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

// Ui - Kit
import PageSection from 'ui-kit/page-section/page-section';

// Components
import PageLayout from 'components/layouts/page/page.layout';

// State
import { accountProfileSelector } from 'state/account/account.selectors';

const OnboardingPage = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);

    useEffect(() => {
        // define the redirection logic based on the profile information
    }, [profileObject]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PageLayout metaData={{ nodeTitle: t('Loading profile...') }}>
            <PageSection className="text-center loading-profile">
                <div style={{ padding: '2rem', height: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', alignItems: 'center' }}>
                        <ButtonComponent
                            label="Terms and Conditions"
                            type="button"
                            disabled
                            onClick={() => navigate('/secure/onboarding/terms-conditions')}
                        />

                        <ButtonComponent
                            label="Profile Setup"
                            type="button"
                            onClick={() => navigate('/secure/onboarding/profile-setup')}
                        />

                        <ButtonComponent
                            label="Health Profile"
                            type="button"
                            onClick={() => navigate('/secure/onboarding/health-profile')}
                        />

                        <ButtonComponent
                            label="Auto Refill"
                            type="button"
                            onClick={() => navigate('/secure/onboarding/auto-refill')}
                        />

                        <ButtonComponent
                            label="Phone Setup"
                            type="button"
                            onClick={() => navigate('/secure/onboarding/mobile-phone-setup')}
                        />

                        <ButtonComponent
                            label="Phone Validation"
                            type="button"
                            onClick={() => navigate('/secure/onboarding/mobile-phone-validation-setup')}
                        />
                    </div>
                </div>
            </PageSection>
        </PageLayout>
    );
};

export default OnboardingPage;

export const query = graphql`
    query AuthPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
